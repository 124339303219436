import React from 'react'
import debounce from 'lodash.debounce'
import { withRouter, Router } from 'next/router'
import { StoresContext } from 'spartacus/stores'

interface Props {
  router: Router
}

class AnalyticsPageview extends React.Component<Props> {
  public static contextType = StoresContext
  private static readonly scrollPercentagesToTrack = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  public context!: React.ContextType<typeof StoresContext>

  private debouncedHandleScroll: () => void
  private remainingScrollPercentagesToTrack = [...AnalyticsPageview.scrollPercentagesToTrack]

  public constructor(props: Props) {
    super(props)

    this.debouncedHandleScroll = debounce(this.handleScroll, 150)
  }

  public componentDidMount = (): void => {
    window.addEventListener('scroll', this.debouncedHandleScroll)
    this.context.analyticsStore.pageview()
  }

  public componentDidUpdate = (prevProps: Props): void => {
    if (prevProps.router.pathname !== this.props.router.pathname) {
      this.context.analyticsStore.pageview()
      this.remainingScrollPercentagesToTrack = [...AnalyticsPageview.scrollPercentagesToTrack]
    }
  }

  public componentWillUnmount = (): void => {
    window.removeEventListener('scroll', this.debouncedHandleScroll)
  }

  public render(): null {
    return null
  }

  private handleScroll = (): void => {
    const bottomMostViewablePixelY = window.scrollY + window.innerHeight
    const body = document.querySelector('body')

    if (!body) {
      return
    }

    const totalHeight = body.scrollHeight
    const percentage = bottomMostViewablePixelY / totalHeight
    const percentageInIncrementsOfTen = Math.trunc(percentage * 10) * 10

    while (this.remainingScrollPercentagesToTrack.length) {
      if (percentageInIncrementsOfTen >= this.remainingScrollPercentagesToTrack[0]) {
        this.context.analyticsStore.event({
          event: 'scrollTracking',
          category: 'scroll tracking',
          action: `${this.remainingScrollPercentagesToTrack[0]}%`,
          label: `${totalHeight}px`,
        })

        this.remainingScrollPercentagesToTrack.shift()
      } else {
        break
      }
    }
  }
}

export default withRouter(AnalyticsPageview)
