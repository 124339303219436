/* spell-checker: disable */

import { createGlobalStyle } from 'styled-components'
import { createMuiTheme } from '@material-ui/core/styles'
import Colors from 'spartacus/styles/colors'

export const theme = createMuiTheme({
  typography: {
    fontFamily: 'TT',
  },
  palette: {
    primary: {
      main: Colors.Brand.Primary1,
    },
    text: {
      primary: Colors.Type.Primary,
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 18,
        backgroundColor: Colors.Type.Primary,
        opacity: 1,
        padding: '12px 16px',
      },
      arrow: {
        color: Colors.Type.Primary,
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: Colors.Brand.Primary4,
      },
    },
    MuiInputBase: {},
    MuiOutlinedInput: {
      input: {
        padding: '20.5px 14px 16.5px',
      },
    },
  },
})

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Mabry';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('https://cdn.spartacus.com/fonts/mabry-bold-pro.woff2') format('woff2'),
      url('https://cdn.spartacus.com/fonts/mabry-bold-pro.woff') format('woff');
  }

  @font-face {
    font-family: 'TT';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: url('https://cdn.spartacus.com/fonts/tt-commons-regular.woff2') format('woff2'),
      url('https://cdn.spartacus.com/fonts/tt-commons-regular.woff') format('woff');
  }

  html,
  body,
  div#next {
    height: 100%;
    background: ${Colors.Brand.Primary5};
    font-family: 'TT', sans-serif;
    font-weight: 400;
    margin: 0;
    -webkit-font-smoothing: antialiased;
  }

  body,
  div#next {
    display: flex;
    flex-direction: column;
  }

  a {
    text-decoration: none;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
`

export const BaselineStyle = createGlobalStyle`
  /* the following was generated by antd */

  html,
  body {
    width: 100%;
    height: 100%;
  }

  input::-ms-clear,
  input::-ms-reveal {
    display: none;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
  }

  @-ms-viewport {
    width: device-width;
  }

  article,
  aside,
  dialog,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display: block;
  }

  body {
    margin: 0;
    color: ${Colors.Type.Primary};
    font-size: 14px;
    font-family: tt, sans-serif;
    font-variant: tabular-nums;
    line-height: 1.5715;
    background-color: ${Colors.UI.White};
    font-feature-settings: 'tnum';
  }

  [tabindex='-1']:focus {
    outline: none !important;
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    font-weight: 500;
  }

  p {
    margin-top: 0;
    margin-bottom: 1em;
  }

  abbr[title],
  abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    border-bottom: 0;
    cursor: help;
  }

  address {
    margin-bottom: 1em;
    font-style: normal;
    line-height: inherit;
  }

  input[type='text'],
  input[type='password'],
  input[type='number'],
  textarea {
    -webkit-appearance: none;
  }

  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1em;
  }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }

  dt {
    font-weight: 500;
  }

  dd {
    margin-bottom: 0.5em;
    margin-left: 0;
  }

  blockquote {
    margin: 0 0 1em;
  }

  dfn {
    font-style: italic;
  }

  b,
  strong {
    font-weight: bolder;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  a {
    color: inherit;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
  }

  a:hover {
    color: ${Colors.Brand.Primary1};
  }

  a:active {
    color: ${Colors.Brand.Primary1};
  }

  a:active,
  a:hover {
    text-decoration: none;
    outline: 0;
  }

  a[disabled] {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    pointer-events: none;
  }

  pre,
  code,
  kbd,
  samp {
    font-size: 1em;
    font-family: sfmono-regular, Consolas, liberation mono, Menlo, Courier, monospace;
  }

  pre {
    margin-top: 0;
    margin-bottom: 1em;
    overflow: auto;
  }

  figure {
    margin: 0 0 1em;
  }

  img {
    vertical-align: middle;
    border-style: none;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  a,
  area,
  button,
  [role='button'],
  input:not([type='range']),
  label,
  select,
  summary,
  textarea {
    touch-action: manipulation;
  }

  table {
    border-collapse: collapse;
  }

  caption {
    padding-top: 0.75em;
    padding-bottom: 0.3em;
    color: rgba(0, 0, 0, 0.45);
    text-align: left;
    caption-side: bottom;
  }

  th {
    text-align: inherit;
  }

  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  html [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }

  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  input[type='radio'],
  input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type='date'],
  input[type='time'],
  input[type='datetime-local'],
  input[type='month'] {
    -webkit-appearance: listbox;
  }

  textarea {
    overflow: auto;
    resize: vertical;
  }

  fieldset {
    min-width: 0;
    margin: 0;
    padding: 0;
    border: 0;
  }

  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    margin-bottom: 0.5em;
    padding: 0;
    color: inherit;
    font-size: 1.5em;
    line-height: inherit;
    white-space: normal;
  }

  progress {
    vertical-align: baseline;
  }

  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    height: auto;
  }

  [type='search'] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }

  [type='search']::-webkit-search-cancel-button,
  [type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }

  output {
    display: inline-block;
  }

  summary {
    display: list-item;
  }

  template {
    display: none;
  }

  [hidden] {
    display: none !important;
  }

  mark {
    padding: 0.2em;
    background-color: #feffe6;
  }

  ::selection {
    color: #fff;
    background: ${Colors.Brand.Primary1};
  }

  .phone {
    white-space: nowrap;
  }
`
