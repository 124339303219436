import React from 'react'
import App from 'next/app'
import dynamic from 'next/dynamic'
import { DefaultSeo as DefaultSEO } from 'next-seo'
import * as Sentry from '@sentry/browser'
import { Elements } from '@stripe/react-stripe-js'
import { OptimizelyProvider } from '@optimizely/react-sdk'
import { ApolloProvider } from '@apollo/react-hooks'
import { ThemeProvider } from '@material-ui/core/styles'
import Constants from 'spartacus/constants'
import SessionStorage from 'spartacus/services/SessionStorage'
import Stripe from 'spartacus/services/Stripe'
import { StoresContext, stores, optimizelyClient, apolloClient } from 'spartacus/stores'
import { BaselineStyle, GlobalStyle, theme } from 'spartacus/styles/global'
import ErrorBoundary from 'spartacus/components/ErrorBoundary'
import ScrollToTopOnNavigationChange from 'spartacus/components/ScrollToTopOnNavigationChange'
import Analytics from 'spartacus/components/Analytics'
import { logger } from 'spartacus/services/LoggerService'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const TestingControlPanel = dynamic(() => import('spartacus/components/TestingControlPanel'), {
  ssr: false,
})

if (Constants.ENVIRONMENT !== 'local') {
  Sentry.init({
    dsn: Constants.SENTRY_DSN,
    environment: Constants.ENVIRONMENT,
    release: `spartacus.com@${Constants.RELEASE_VERSION}`,
    ignoreErrors: [
      'Wrong email or password.',
      'Something went wrong',
      'Non-Error promise rejection captured with value: Timeout',
      'Non-Error promise rejection captured with value: Timeout (n)',
      'vid_mate_check is not defined',
      'No error message',
    ],
  })

  console.log(`Spartacus.com v${Constants.RELEASE_VERSION}`) // eslint-disable-line no-console

  const transactionID = Math.random()
    .toString(36)
    .substr(2, 9)

  SessionStorage.set('transactionID', transactionID)
  Sentry.configureScope(scope => {
    scope.setTag('transaction_id', transactionID)
  })
  logger.addContext('transactionID', transactionID)
}

export default class SpartacusApp extends App {
  public componentDidMount(): void {
    // Remove the server-side injected CSS for Material UI
    const jssStyles = document.querySelector('#jss-server-side')

    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }

  public render(): JSX.Element {
    const { Component, pageProps } = this.props

    return (
      <OptimizelyProvider optimizely={optimizelyClient} isServerSide={!Constants.IS_BROWSER}>
        <ApolloProvider client={apolloClient}>
          <StoresContext.Provider value={stores}>
            <ThemeProvider theme={theme}>
              <ErrorBoundary>
                <Elements
                  stripe={Stripe.instancePromise}
                  options={{
                    fonts: [
                      {
                        family: 'TT',
                        src: `url(https://cdn.spartacus.com/fonts/tt-commons-regular.woff2)`,
                        weight: '400',
                      },
                    ],
                  }}
                >
                  <DefaultSEO
                    title="Spartacus"
                    titleTemplate="%s | Spartacus"
                    openGraph={{
                      type: 'website',
                      images: [
                        {
                          url: 'https://spartacus.com/social-share.png',
                        },
                      ],
                    }}
                    twitter={{
                      site: 'getspartacus',
                      cardType: 'summary_large_image',
                    }}
                  />
                  <BaselineStyle />
                  <GlobalStyle />
                  <ScrollToTopOnNavigationChange />
                  <Analytics />
                  {Constants.ENVIRONMENT !== 'production' && <TestingControlPanel />}
                  <Component {...pageProps} />
                </Elements>
              </ErrorBoundary>
            </ThemeProvider>
          </StoresContext.Provider>
        </ApolloProvider>
      </OptimizelyProvider>
    )
  }
}
