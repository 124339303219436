import React from 'react'
import { observer } from 'mobx-react'
import * as Sentry from '@sentry/browser'
import { StoresContext } from 'spartacus/stores'
import Typography from 'spartacus/components/Typography'
import Button from 'spartacus/components/Button'
import * as S from './style'

export interface Props {
  children: React.ReactNode
}

interface State {
  hasError: boolean
  sentryEventID: string | null
}

@observer
class ErrorBoundary extends React.Component<Props, State> {
  public context!: React.ContextType<typeof StoresContext>

  public state: State = {
    hasError: false,
    sentryEventID: null,
  }

  public static getDerivedStateFromError(): Partial<State> {
    return { hasError: true }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public componentDidCatch(error: any, errorInfo: { [prop: string]: any }): void {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      const sentryEventID = Sentry.captureException(error)

      this.setState({ sentryEventID })
    })

    this.context.analyticsStore.event({
      category: 'state',
      action: 'error',
    })
  }

  public render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <S.Container>
          <S.Content>
            <Typography element="h1" kind="TS4 - Strong">
              Oops!
            </Typography>
            <Typography element="h2" kind="TS3 - Strong">
              Looks like we&rsquo;re running into some trouble on our end.
            </Typography>
            <Typography element="p">
              Rest assured we&rsquo;ve been notified and are working to fix this ASAP. If
              you&rsquo;d like to help, you can tell us a bit about where things went wrong.
              Otherwise, try reloading the page.
            </Typography>
            <S.CTAContainer>
              <Button
                variant="text"
                onClick={(): void => {
                  const options = this.state.sentryEventID
                    ? { eventId: this.state.sentryEventID }
                    : {}

                  Sentry.showReportDialog(options)
                }}
              >
                Report Feedback
              </Button>
              <Button
                onClick={(): void => {
                  if (typeof window !== 'undefined') {
                    window.location.href = '/'
                  }
                }}
              >
                Go Home
              </Button>
              <Button
                onClick={(): void => {
                  if (typeof window !== 'undefined') {
                    window.location.reload(true)
                  }
                }}
              >
                Reload
              </Button>
            </S.CTAContainer>
          </S.Content>
        </S.Container>
      )
    }

    return this.props.children
  }
}

ErrorBoundary.contextType = StoresContext

export default ErrorBoundary
