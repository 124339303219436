import styled from 'styled-components'
import dimensions from 'spartacus/styles/dimensions'

export const Container = styled.div`
  padding: 4vh ${dimensions.nColumns(2)};
`

export const Content = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  @media ${dimensions.md} {
    text-align: center;
  }
`

export const CTAContainer = styled.div`
  margin: 24px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;

  button {
    margin: 0 0 16px;

    &:last-child {
      margin: 0;
    }
  }

  @media ${dimensions.md} {
    flex-direction: row;

    button {
      margin: 0 16px 0 0;

      &:last-child {
        margin: 0;
      }
    }
  }
`
